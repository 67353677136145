const useRefetchClient = async () => {
  const { setClient } = useClientStore();
  const { refetch } = await useGetClientDto();
  const item = await refetch();

  if (item?.data.client) {
    setClient(item.data.client);
  }
};

export { useRefetchClient };
